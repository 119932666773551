import react, {useState} from 'react';
import {quizScores} from '../api';
import {useSearchParams} from 'react-router-dom';

const QuizScoreboard = () => {
    const [searchParams] = useSearchParams();
    const event = searchParams.get('event');

    const [scores, setScores] = useState([]);
    react.useEffect(() => {
        quizScores(event).then(s => setScores(s.sort((a, b) => b.score - a.score)));
    }, [])

    return <div className=" text-white w-full h-full font-bold mt-5 text-center" style={{fontFamily: 'pixel'}}>
        <ul>
            {scores.map((score, idx) => {
                return <li className={idx === 0 ? "text-9xl rainbow-text mb-8" : "text-xl glow"}
                           key={`${score.email}-${idx}`}>{idx + 1}. {score.email} - {score.score}</li>
            })}
        </ul>
    </div>
}

export {QuizScoreboard};