import React from 'react';
import react, {useState} from 'react';
import {getQuiz, writeQuiz} from '../api';
import {useSearchParams} from 'react-router-dom';
import {LoadingSpinner} from "./LoadingSpinner";

const Quiz = () => {
    const [searchParams] = useSearchParams();
    const event = searchParams.get('event');

    const [email, setEmail] = useState('');
    const [error, setError] = React.useState("")
    const [emailSubmitted, setEmailSubmitted] = React.useState(false);
    const [questions, setQuestions] = React.useState([]);
    const [answers, setAnswers] = React.useState([]);
    const [score, setScore] = React.useState(0);
    const [scoreLoading, setScoreLoading] = useState(false);

    react.useEffect(() => {
        getQuestions();
    }, [])

    function onSubmit() {
        if (!email) {
            setError('Name and Email are required pweaaase.')
        } else {
            setEmailSubmitted(true);
        }
    }

    async function getQuestions() {
        const response = await getQuiz(event);
        setQuestions(response)
    }

    return (
        <div className="w-full h-full" style={{fontFamily: 'pixel'}}>
            {!emailSubmitted && <div className={'entryForm'}>
                <div className={'entryInput'}>
                    <div>
                        <label className={'text-white mt-10 label'} htmlFor="email">NickName</label>
                        <input className={'text-black mt-10 border rounded-2xl border-white p-2'} id="email"
                               value={email}
                               required={true} onChange={(event) => setEmail(event.target.value)}/>
                    </div>
                </div>
                <p className={'mt-8 mb-2 text-red-500 self-end content-center'}>{error ? error : ''}</p>
                <button
                    className={'submit text-white border rounded-2xl border-white p-2 hover:bg-white hover:text-black'}
                    name="submit" onClick={onSubmit}>Submit
                </button>
            </div>}

            {emailSubmitted && !(answers.length === questions.length) &&
                <div className={"text-white flex items-center justify-center flex-col w-full h-full mt-5"}
                     style={{fontFamily: 'pixel'}}>
                    <h1 className={'text-3xl font-bold text-center glow'}>Question: {answers.length + 1}</h1>
                    <div className={"flex flex-row"}>
                        <Answer text={questions[answers.length].A} answer={"A"}/>
                        <Answer text={questions[answers.length].B} answer={"B"}/>
                    </div>
                    <div className={"flex flex-row"}>
                        <Answer text={questions[answers.length].C} answer={"C"}/>
                        <Answer text={questions[answers.length].D} answer={"D"}/>
                    </div>
                    <button onClick={() => submitAnswer(null)}
                            className={"bg-gray-400 hover:bg-gray-600 font-bold py-2 px-4 rounded m-5"}>Skip
                    </button>
                </div>
            }

            {(answers.length && answers.length === questions.length) &&
                <div className={'flex items-center justify-center flex-col w-full h-full mt-5 text-white'}>
                    <h1 className={'text-3xl font-bold text-center glow'}>Your final score is:</h1>
                    {scoreLoading ? <LoadingSpinner/> :
                        <h1 className={'text-3xl font-bold text-center glow'}>{score}</h1>}
                </div>}

        </div>
    );

    async function submitAnswer(answer) {
        const ansCopy = [...answers];
        ansCopy.push(answer);
        setAnswers(ansCopy);
        if (ansCopy.length === questions.length) {
            setScoreLoading(true);
            const score = await writeQuiz(event, ansCopy, email);
            setScore(score[email]);
            setScoreLoading(false);
        }
    }

    function Answer({text, answer}) {
        return <button onClick={() => submitAnswer(answer)}
                       className={"bg-blue-400 hover:bg-blue-700 font-bold py-2 px-4 rounded m-5"}> {text}</button>
    }
}


export {Quiz};