import React from 'react';
import ltWhiteLogo from "../resources/LT-logo.png";
import devops2024 from "../resources/2024-devopsdays.png";
import {useSearchParams} from "react-router-dom";
import tbd from "../resources/tbd-xmas.png";

const Header = () => {
    const [searchParams] = useSearchParams();
    const event = searchParams.get('event');

    return (<>
        {event ? <div className={'w-full h-32 sticky bg-black z-10 top-0'}>
                <div className={'flex flex-row items-center justify-between mx-4 border-b-2 border-white'}>
                    <img src={ltWhiteLogo} alt={'Lean Techniques all white logo'}
                         className={'max-h-10 md:max-h-18 lg:max-h-28'}/>
                    <h1 className={'text-white text-2xl md:text-3xl lg:text-5xl mr-18 lg:mr-48 text-center'}
                        style={{fontFamily: 'pixel'}}>LT Holiday Party 2024!</h1>
                    <img src={tbd} alt={'Tim Brandon and Danielle in Xmas Sweaters'}
                         className={'max-h-16 md:max-h-24 lg:max-h-32 logo'}/>
                </div>
            </div> :
            <div className={'w-full h-32 sticky bg-black z-10 top-0'}>
                <div className={'flex flex-row items-center justify-between mx-4 border-b-2 border-white'}>
                    <img src={ltWhiteLogo} alt={'Lean Techniques all white logo'}
                         className={'max-h-10 md:max-h-18 lg:max-h-28'}/>
                    <h1 className={'text-white text-2xl md:text-3xl lg:text-5xl mr-18 lg:mr-48 text-center'}
                        style={{fontFamily: 'pixel'}}>Raise Some <span
                        className={'font-bold'}>Hell</span>o World <br/>Challenge</h1>
                    <img src={devops2024} alt={'DevOpsDays 2024 Logo'}
                         className={'max-h-16 md:max-h-24 lg:max-h-32 logo'}/>
                </div>
            </div>}
    </>);
};

export {Header};