import axios from "axios";

const getScores = async () => {
    return (await axios({method: 'get', url: process.env.REACT_APP_API_URL})).data;
};

const getRaffleEntrants = async () => {
    return (await axios({method: 'get', url: `${process.env.REACT_APP_API_URL}?raffle=Y`})).data;
};

const addRaffleEntry = async (name, email, title, company) => {
    return (await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_POST_URL}`,
        data: {name: name, emailAddress: email, title: title, company: company}
    })).status;
}

const getQuiz = async (event) => {
    return (await axios({method: 'get', url: `${process.env.REACT_APP_API_GET_QUIZ_URL}?event=${event}`})).data;
}

const writeQuiz = async (event, answers, email) => {
    return (await axios({
        method: 'post', url: `${process.env.REACT_APP_API_WRITE_QUIZ_URL}?event=${event}`, data: {answers, email},
    })).data;
}

const quizScores = async (event) => {
    return (await axios({method: 'get', url: `${process.env.REACT_APP_API_SCORES_QUIZ_URL}?event=${event}`})).data;
}

export {getScores, getRaffleEntrants, addRaffleEntry, getQuiz, writeQuiz, quizScores};